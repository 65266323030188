import { useState } from "react";
import { MdEmail, MdMenuOpen, MdMenu } from "react-icons/md";

const App = () => {
  const [dial, setDial] = useState(false);


  const toggleDial = () => {
    setDial(!dial);
  };

  return (
    <>
      <div className="h-screen w-screen bg-peru bg-cover bg-bottom justify-center items-center p-0">
        <div className="h-full backdrop-grayscale">

          <div data-dial-init className="fixed right-6 bottom-6 group">
            <div
              id="speed-dial-menu-bottom-right"
              className={
                dial ? "flex flex-col items-center mb-4 space-y-2" : "hidden"
              }
            >
              <button
                type="button"
                onClick={() =>
                  (window.location = "mailto:laurence@laurenceodonnell.co.uk")
                }
                className="relative w-[64px] h-[64px] text-orange bg-white rounded-full border border-white hover:border-orange shadow-sm"
              >
                <MdEmail className="w-6 h-6 mx-auto mt-px" />
                <span className="block mb-px text-xs font-medium">Contact</span>
              </button>
            </div>

            <button
              type="button"
              onClick={toggleDial}
              className="relative w-[64px] h-[64px] text-white bg-orange rounded-full border border-orange hover:border-white shadow-sm"
            >
              {!dial ? (
                <MdMenu className="w-6 h-6 mx-auto mt-px" />
              ) : (
                <MdMenuOpen className="w-6 h-6 mx-auto mt-px" />
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
